@import '../stylesheet';

/* START THEME
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_logo {
  // never more then width: 200px and height 60px
  svg.main-logo-transtaboo {width: 200px; height: 50px;}
}

.module-footer {
  svg.main-logo-transtaboo {width: 160px; height: 40px;}
}

// RESPONSIVE - CUSTOM
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media screen and (min-width: 1000px) and (max-width: 1048px) {
  .module-header_logo svg.main-logo {width: 160px !important;}
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .module-header_logo {
    // never more then width: 170px and height 35px
    svg.main-logo-transtaboo {width: 135px; height: 30px;}
  }
}

// RESPONSIVE - Mobile Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  .module-header_logo {
    // never more then width: 125px and height 35px
    svg.main-logo-transtaboo {width: 115px; height: 30px;}
  }
}











